import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  
  static values = {url: String}

  search(event) {
    console.log("searching...")
    console.log(event.target.name)
    console.log(event.target.value)
    // Turbo.visit(`?${event.target.name}=${event.target.value}`)
    get(`${this.urlValue}?${event.target.name}=${event.target.value}`, {
      responseKind: "turbo-stream",
    })
  }
}