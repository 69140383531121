import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["country", "state", "city"];
  static values = { url: String };

  changeCountry() {
    fetch(`${this.urlValue}?country=${this.countryTarget.value}`, {
      credentials: "include",
    })
      .then((response) => response.text())
      .then((html) => {
        this.stateTarget.innerHTML = html;
        this.changeState();
      });
  }

  changeState() {
    fetch(
      `${this.urlValue}?country=${this.countryTarget.value}&state=${this.stateTarget.value}`,
      {
        credentials: "include",
      }
    )
      .then((response) => response.text())
      .then((html) => {
        this.cityTarget.innerHTML = html;
      });
  }
}
