import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "toggleable", "checkboxAll"];
  static classes = ["toggle"];

  toggle(event) {
    this.toggleableTargets.forEach((toggleable) => {
      if (event.target.dataset.toggle == toggleable.dataset.toggle) {
        toggleable.classList.toggle(this.toggleClass, !event.target.checked);
      }
    });
    this.checkboxAllTargets.forEach((checkbox) => {
      if (checkbox.dataset.depth.startsWith(event.target.dataset.toggle)) {
        checkbox.checked = event.target.checked;
        this.triggerInputEvent(checkbox);
      }
    });
  }

  toggleAll(event) {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.dataset.depth.startsWith(event.target.dataset.depth)) {
        checkbox.checked = event.target.checked;
        this.triggerInputEvent(checkbox);
      }
    });
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }
}
