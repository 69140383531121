import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["validatable", "button", "loading"];
  static classes = ["input", "hidden"];

  validate(event) {
    let continueSubmission = true;
    this.validatableTargets.forEach((el) => {
      if (el.value.length == 0) {
        continueSubmission = false;
        el.classList.toggle(this.inputClass, true);
        if (el.nextElementSibling) {
          el.nextElementSibling.classList.toggle(this.hiddenClass, false);
        }
      } else {
        el.classList.toggle(this.inputClass, false);
        if (el.nextElementSibling) {
          el.nextElementSibling.classList.toggle(this.hiddenClass, true);
        }
      }
    });

    if (!continueSubmission) {
      event.preventDefault();
      this.buttonTarget.classList.toggle(this.hiddenClass, false);
      this.buttonTarget.disabled = false;
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add(this.hiddenClass);
      }
      return false;
    } else {
      this.buttonTarget.classList.toggle(this.hiddenClass, true);
      this.buttonTarget.disabled = true;
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.remove(this.hiddenClass);
      }
    }
  }
}
