import { Controller } from "@hotwired/stimulus";

import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = ["map"];
  static values = { lat: Number, long: Number, publicKey: String };

  initialize() {
    mapboxgl.accessToken = this.publicKeyValue;
  }

  connect() {
    console.log("Mapbox controller connected");
    this.hoveredStateId = null;

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/outdoors-v10",
      center: [this.longValue, this.latValue], // starting position
      zoom: 11, // starting zoom
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.GeolocateControl());
    this.map.addControl(new mapboxgl.FullscreenControl());

    this.map.on("load", () => {
      this.onMapLoaded();
    });
  }

  disconnect() {
    this.map.remove();
    this.map = undefined;
  }

  // Do something when the map has loaded.
  onMapLoaded() {

    const voterLocation = new mapboxgl.Marker()
      .setLngLat([this.longValue, this.latValue])
      .addTo(this.map);
  }
}
