import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["input", "hiddenInput", "options", "option"];
  static classes = [
    "hidden",
    "active",
    "notActive",
    "selected",
    "checkboxSelected",
    "checkboxNotSelected",
  ];
  static values = {
    searchUrl: String,
    geocodeUrl: String,
    publicToken: String,
    sessionId: String,
  };

  connect() {
    this.filter();
  }

  mouseenter(event) {
    event.target.classList.add(...this.activeClasses);
    event.target.classList.remove(...this.notActiveClasses);
  }

  mousedown(event) {
    this.inputTarget.value = event.target.dataset.name;
    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = event.target.dataset.value;
    }
    this.inputTarget.dispatchEvent(new CustomEvent("input"));
  }

  mouseleave(event) {
    event.target.classList.remove(...this.activeClasses);
    event.target.classList.add(...this.notActiveClasses);
  }

  toggleDropdown() {
    if (this.optionsTarget.classList.contains(this.hiddenClass)) {
      this.showDropdown();
    } else {
      this.hideDropdown();
    }
  }

  showDropdown() {
    enter(this.optionsTarget);
  }

  hideDropdown() {
    leave(this.optionsTarget);
  }

  select(event) {
    this.inputTarget.value = event.target.dataset.name;
    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = event.target.dataset.value;
    }
    this.inputTarget.dispatchEvent(new CustomEvent("input"));
    this.hideDropdown();
  }

  keyboardShortcut(event) {
    event.preventDefault();
    this.hideDropdown();
    return false;
  }

  filter() {
    if (this.inputTarget.value.length > 0) {
      fetch(
        `${
          this.searchUrlValue
        }${this.inputTarget.value.toLowerCase()}&access_token=${
          this.publicTokenValue
        }&session_token=${this.sessionIdValue}&types=city,street,address`
      )
        .then((response) => response.json())
        .then((json) => {
          var html = "";
          json.suggestions.forEach((suggestion) => {
            html = `${html}
            <li class="relative py-2 pl-3 mx-1 text-gray-900 rounded-md cursor-default select-none pr-9" 
              data-name="${suggestion.name} ${suggestion.place_formatted}" 
              data-value="${suggestion.mapbox_id }" 
              role="option" 
              tabindex="-1" 
              data-action=" click->address-search#select mouseenter->address-search#mouseenter mousedown->address-search#mousedown mouseleave->address-search#mouseleave"
              data-address-search-target="option">
              ${suggestion.name} ${suggestion.place_formatted}
            </li>`;
          });
          this.optionsTarget.innerHTML = html;
          this.dispatch("addressFilled", {});
        });
    }
  }

  loadLocation({ detail: { lat, lng } }) {
    fetch(`${this.geocodeUrlValue}${lng},${lat}.json?types=address&limit=1&access_token=${
      this.publicTokenValue
    }`).then((response) => response.json())
    .then((json) => {
      var html = "";
      json.features.forEach((suggestion) => {
        html = `${html}
        <li class="relative py-2 pl-3 mx-1 text-gray-900 rounded-md cursor-default select-none pr-9" 
          data-name="${suggestion.place_name}" 
          data-value="${suggestion.id}" 
          role="option" 
          tabindex="-1" 
          data-action=" click->address-search#select mouseenter->address-search#mouseenter mousedown->address-search#mousedown mouseleave->address-search#mouseleave"
          data-address-search-target="option"> ${suggestion.place_name}
        </li>`;
      });
      this.optionsTarget.innerHTML = html;
      
      this.inputTarget.value = json.features[0].place_name;
      if (this.hasHiddenInputTarget) {
        this.hiddenInputTarget.value = "";
      }
      this.dispatch("addressFilled", {});
    });
  }

}
