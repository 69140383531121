import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["star", "input"];
  static classes = ["active", "inactive"];

  select(event) {
    var node = event.target;
    if (node.dataset.value == undefined) {
      node = event.target.parentNode;
    }
    this.starTargets.forEach((star) => {
      if (star.dataset.value <= node.dataset.value) {
        star.classList.remove(this.inactiveClass);
        star.classList.add(this.activeClass);
      } else {
        star.classList.remove(this.activeClass);
        star.classList.add(this.inactiveClass);
      }
    });
    this.inputTarget.value = node.dataset.value;
  }
}
