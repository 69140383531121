import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phonenumber", "email"];

  selectDriver(event) {
    if (this.hasPhonenumberTarget) {
      this.phonenumberTarget.value = event.target.dataset.phonenumber;
    }
    if (this.hasEmailTarget) {
      this.emailTarget.value = event.target.dataset.email;
    }
  }
}
