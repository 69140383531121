import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addressBar",
    "locationButton",
    "submitButton",
    "latitudeField",
    "longitudeField",
  ];

  connect() {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      console.log("Geolocation is available");
      let controller = this;
      navigator.geolocation.getCurrentPosition((position) => {
        controller.locationButtonTarget.disabled = false;
        controller.submitButtonTarget.disabled = false;
        controller.latitudeFieldTarget.value = position.coords.latitude;
        controller.longitudeFieldTarget.value = position.coords.longitude;
        controller.dispatch("retreivedLocation", {
          detail: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    } else {
      /* geolocation IS NOT available */
      console.log("No Geolocation!");
      this.locationButtonTarget.hidden = true;
    }
  }

  loadLocation(event) {
    event.preventDefault();
    this.locationButtonTarget.disabled = true;
    let controller = this;
    navigator.geolocation.getCurrentPosition((position) => {
      controller.locationButtonTarget.disabled = false;
      controller.submitButtonTarget.disabled = false;
      controller.latitudeFieldTarget.value = position.coords.latitude;
      controller.longitudeFieldTarget.value = position.coords.longitude;
      controller.dispatch("retreivedLocation", {
        detail: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
    return false;
  }

  addressFilled() {
    console.log("address filled");
    this.submitButtonTarget.disabled = false;
  }
}
