// pagy_controller.js - a stimulus JS controller
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "pageInput"];

  connect() {
    for (const link of this.element.getElementsByTagName("a")) {
      link.addEventListener("click", this.submitForm.bind(this));
    }
  }

  submitForm(event) {
    event.preventDefault();
    let pageNumber = event.target.getAttribute("href");
    if (pageNumber === "#") {
    } else {
      this.formTarget.action = pageNumber;
      this.formTarget.requestSubmit();
    }
  }
}
