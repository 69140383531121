import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "toggleable"];
  static classes = ["active", "inactive", "hidden"];
  static values = { showToggle: String };
  connect() {
    this.buttonTargets.forEach((button) => {
      console.log(button.checked);
      if (button.checked) {
        button.parentElement.classList.remove(...this.inactiveClasses);
        button.parentElement.classList.add(...this.activeClasses);
        if (this.hasToggleableTarget) {
          if (button.value != this.showToggleValue) {
            this.toggleableTarget.classList.remove(this.hiddenClass);
          }
        }
      } else {
        button.parentElement.classList.remove(...this.activeClasses);
        button.parentElement.classList.add(...this.inactiveClasses);
      }
    });
  }

  select(event) {
    this.buttonTargets.forEach((button) => {
      console.log(button == event.target);
      if (button == event.target) {
        button.parentElement.classList.remove(...this.inactiveClasses);
        button.parentElement.classList.add(...this.activeClasses);
      } else {
        button.parentElement.classList.remove(...this.activeClasses);
        button.parentElement.classList.add(...this.inactiveClasses);
      }
    });
    if (this.hasToggleableTarget) {
      if (event.target.value != this.showToggleValue) {
        this.toggleableTarget.classList.remove(this.hiddenClass);
      } else {
        this.toggleableTarget.classList.add(this.hiddenClass);
      }
    }
  }
}
