import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button"];

  connect() {
    console.log("clear_controller connected");
    this.toggleButton();
  }

  change() {
    this.toggleButton();
  }

  toggleButton()  {
    this.buttonTarget.classList.toggle("hidden", this.inputTarget.value.length == 0);
    this.inputTarget.classList.toggle("rounded-r-md", this.inputTarget.value.length != 0);
  }

  clearField() {
    document.getElementById("load_pickup_time_open").flatpickr().clear();
    // this.inputTarget.value = "";
    this.toggleButton();
    this.inputTarget.focus();
  }
}