import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "output"];
  static values = { message: String, limit: Number };

  connect() {
    this.updateCount();
  }

  fieldChange() {
    this.updateCount();
  }

  updateCount() {
    this.outputTarget.innerText = `${this.messageValue} (${this.fieldTarget.value.length})`;
  }
}
