import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  moveUp(event) {
    console.log("sortable move up");
    event.preventDefault();
    const item = event.target.closest(".sortable-item");
    let previous = item.previousElementSibling;
    while (previous && !previous.classList.contains("sortable-item")) {
      previous = previous.previousElementSibling;
    }
    if (previous) {
      item.parentNode.insertBefore(item, previous);
      item.dataset.order = Number(item.dataset.order) - 1;
      item.getElementsByClassName("order-field")[0].value = item.dataset.order;
      previous.dataset.order = Number(previous.dataset.order) + 1;
      previous.getElementsByClassName("order-field")[0].value =
        previous.dataset.order;
    }
  }

  moveDown(event) {
    console.log("sortable move down");
    event.preventDefault();
    const item = event.target.closest(".sortable-item");
    let next = item.nextElementSibling;
    while (next && !next.classList.contains("sortable-item")) {
      next = next.nextElementSibling;
    }
    if (next) {
      item.parentNode.insertBefore(next, item);
      item.dataset.order = Number(item.dataset.order) + 1;
      item.getElementsByClassName("order-field")[0].value = item.dataset.order;
      next.dataset.order = Number(next.dataset.order) - 1;
      next.getElementsByClassName("order-field")[0].value = next.dataset.order;
    }
  }
}
