import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["phonenumber", "truck", "trailer"];

  selectDriver(event) {
    if (this.hasPhonenumberTarget) {
      this.phonenumberTarget.value = event.target.dataset.phonenumber;
    }
    if (this.hasTruckTarget) {
      this.truckTarget.value = event.target.dataset.truck;
    }
    if (this.hasTrailerTarget) {
      this.trailerTarget.value = event.target.dataset.trailer;
    }
  }
}
