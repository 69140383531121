import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["input", "hiddenInput", "options", "option"];
  static classes = [
    "hidden",
    "active",
    "notActive",
    "selected",
    "checkboxSelected",
    "checkboxNotSelected",
  ];
  static values = {
    url: String,
    visitUrl: Boolean,
  };

  connect() {
    this.filter();
  }

  mouseenter(event) {
    event.target.classList.add(...this.activeClasses);
    event.target.classList.remove(...this.notActiveClasses);
  }

  mousedown(event) {
    this.inputTarget.value = event.target.dataset.name;
    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = event.target.dataset.value;
    }
    this.inputTarget.dispatchEvent(new CustomEvent("input"));

    if (this.hasVisitUrlValue && this.visitUrlValue) {
      Turbo.visit(event.target.dataset.value);
    }
  }

  mouseleave(event) {
    event.target.classList.remove(...this.activeClasses);
    event.target.classList.add(...this.notActiveClasses);
  }

  toggleDropdown() {
    if (this.optionsTarget.classList.contains(this.hiddenClass)) {
      this.showDropdown();
    } else {
      this.hideDropdown();
    }
  }

  showDropdown() {
    enter(this.optionsTarget);
  }

  hideDropdown() {
    leave(this.optionsTarget);
  }

  select(event) {
    this.inputTarget.value = event.target.dataset.name;
    if (this.hasHiddenInputTarget) {
      this.hiddenInputTarget.value = event.target.dataset.value;
    }
    this.inputTarget.dispatchEvent(new CustomEvent("input"));
    this.hideDropdown();
    if (this.hasVisitUrlValue && this.visitUrlValue) {
      Turbo.visit(event.target.dataset.value);
    }
  }

  keyboardShortcut(event) {
    event.preventDefault();
    this.hideDropdown();
    return false;
  }

  filter() {
    if (this.hasUrlValue) {
      fetch(`${this.urlValue}?name=${this.inputTarget.value.toLowerCase()}`, {
        credentials: "include",
      })
        .then((response) => response.text())
        .then((html) => {
          this.optionsTarget.innerHTML = html;
        });
    } else {
      this.optionTargets.forEach((option) => {
        if (
          option.dataset.name
            .toLowerCase()
            .includes(this.inputTarget.value.toLowerCase())
        ) {
          option.classList.remove(this.hiddenClass);
        } else {
          option.classList.add(this.hiddenClass);
        }
      });
    }
  }
}
